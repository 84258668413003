<template>
  <div class="w-full">
    <vs-row vs-type="flex" vs-w="12">
      <vs-col vs-w="3" class="px-2">
        <rotaLista @update="selectRot" />
      </vs-col>

      <vs-col vs-w="9" class="px-2">
        <div id="tecnico_card" class="vs-con-loading__container">
          <vx-card title="Técnicos" class="w-full" >
            <template slot="actions">
              <vs-button
                size="small"
                color="success"
                type="relief"
                :disabled="!$atividades.permissoes.includes(14) || selectedRot == null"
                @click="assocTecnicos()"
              >Associar</vs-button>
            </template>

            <div class="w-full">
              <vs-row vs-w="12" vs-type="flex" vs-justify="space-between" class="w-full">
                <vs-col
                  vs-w="6"
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  v-for="(tecnico, index) in tecnicos"
                  :key="index"
                >
                  <div class="vx-row mb-1" :key="reRender">
                    <div class="vx-col">
                      <vs-switch color="success" v-model="tecnico.status" @click="addToRota(tecnico.id)"/>
                    </div>
                    <div class="vx-col">
                      <h5>{{ tecnico.nome }}</h5>
                    </div>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </vx-card>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import rotaLista from "./components/rotaLista.vue";
import draggable from "vuedraggable";
import components from "@/components/default/exports.js";

export default {
  data() {
    return {
      reRender: 1,
      selectedRot: null,
      tecnicos: []
    };
  },
  methods: {
    async selectRot(rota) {
      this.selectedRot = rota.id;
      this.getTecByRota();
    },
    async assocTecnicos() {
      await this.$vs.loading({ container: "#tecnico_card", scale: 0.6 });
      const tecnicos = [];
      this.tecnicos.forEach(obj => {
          if(obj.status == 1){
            tecnicos.push(obj.id);
          }
      })
      try {
         await this.$http.post("associarColaboradorRota", {
          id: this.selectedRot,
          tecnicos: tecnicos
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#tecnico_card > .con-vs-loading");
      }
    },
    async getTecByRota() {
      await this.$vs.loading({
        container: '#tecnico_card',
        scale: 0.6
      })
      let i = 0
      this.tecnicos.forEach(obj => {
        obj
        this.tecnicos[i].status = false
        i++
      })
      try {
        const res = await this.$http.get(`getTecByRota/${this.selectedRot}`);
        res.forEach(obj => {
          let value = this.tecnicos.map(x => x.id).indexOf(obj.id)
          if(value !== -1){
            this.tecnicos[value].status = true
          }
        });
        this.reRender = this.reRender + 1
        await this.$vs.loading.close('#tecnico_card > .con-vs-loading');
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
        await this.$vs.loading.close('#tecnico_card > .con-vs-loading');
      }
    },
    async addToRota(idTec) {
      let value = this.tecnicos.map(x => x.id).indexOf(idTec)
      if(value !== -1){
        this.tecnicos[value].status = !this.tecnicos[value].status
      }
      this.reRender = this.reRender + 1
    },
    async getTecnicos() {
      const atividades = [10];
      this.$vs.loading();
      try {
        this.tecnicos = await this.$http.post(
          `colaboradorAtividade`,
          atividades
        );
        let i = 0
        await this.tecnicos.forEach(async obj => {
          obj
          this.tecnicos[i].status = false
          i++
        })
        await this.$vs.loading.close();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
        await this.$vs.loading.close();
      }
    }
  },
  async mounted() {
    await this.getTecnicos();
  },
  components: {
    ...components,
    rotaLista,
    draggable
  }
};
</script>
